document.addEventListener("DOMContentLoaded", () => {
  function attachToggleClassBehaviour(element) {
    const toggleClass = element.getAttribute("data-toggle-class");
    element.addEventListener("click", () => {
      if (element.hasAttribute("data-toggle-class-target")) {
        document
          .querySelectorAll(element.getAttribute("data-toggle-class-target"))
          .forEach((element) => {
            element.classList.toggle(toggleClass);
          });
      } else {
        element.classList.toggle(toggleClass);
      }
      return true;
    });
  }

  document
    .querySelectorAll("[data-toggle-class]")
    .forEach(attachToggleClassBehaviour);
  document.addEventListener("htmx:afterProcessNode", (event) => {
    event.detail.elt
      .querySelectorAll("[data-toggle-class]")
      .forEach(attachToggleClassBehaviour);
  });
});
